import React from 'react';
import Event from '../../Event/Event';

const BlogPost = ({ archiveNodes }) => {
  return (
    <div className="events-container">
      {archiveNodes?.nodes?.map((event, index) => {
        return <Event key={index} data={event} />;
      })}
    </div>
  );
};

export default BlogPost;
