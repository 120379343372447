import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import checkExtraProps from '@jam3/react-check-extra-props';

import './Event.scss';

import moment from 'moment';
import GatsbyImage from 'gatsby-image';
import { customPropTypes, renderDateRange, sanitizer } from '../../util';

const Event = ({ data }) => {
  const {
    title,
    databaseId,
    eventPostFields: { link, beginDate, endDate, location, image },
  } = data;

  const expiredClass = moment(endDate, 'DD/MM/YYYY') < moment() ? ' expired' : '';

  return (
    <a href={link} key={databaseId} target="_blank" className={`event${expiredClass}`} rel="noopener noreferrer">
      <div className="image-wrapper">
        <GatsbyImage fluid={image?.localFile.childImageSharp.fluid} alt={image?.altText} />
      </div>
      <span className="date" dangerouslySetInnerHTML={{ __html: sanitizer(renderDateRange(beginDate, endDate)) }} />
      <h4 className="event-title">{title}</h4>
      <span className="location">{location}</span>
    </a>
  );
};

Event.propTypes = checkExtraProps({
  data: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    databaseId: PropTypes.number,
    eventPostFields: PropTypes.shape({
      link: PropTypes.string,
      beginDate: PropTypes.string,
      endDate: PropTypes.string,
      location: PropTypes.string,
      image: customPropTypes.image,
    }),
  }),
});

export default Event;
